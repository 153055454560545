/**
 * @usage:
 *
 *   <BaseSelect :options="['Foo','Bar']" label="Name" :rules="{required:true}" :isRequired="true"  v-model="name" />

 *
 * data(){
 *    return {
 *      name: "",
 *    }
 *  }
 */
<script>
import { BFormSelect } from 'bootstrap-vue'

export default {
  components: { BFormSelect },
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'modified',
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      required: true,
    },
    containerClass: {
      type: String,
    },
    rules: {
      type: Object,
      required: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },

  },
  emits: ['modified'],
  data() {
    return {
      selected: null,
    }
  },
  watch: {
    selected: {
      handler(newValue) {
        this.$emit('modified', newValue)
      },
      immediate: true,
    },
  },

}
</script>

<template>
  <ValidationProvider v-slot="{ errors, valid }" :rules="rules">
    <div class="FormInput " :class="containerClass">
      <label :for="label" class="FormInput__inputContainer">
        <p class="FormInput__heading">
          <span>{{ label }}</span>
          <span v-if="isRequired" class="text-danger">*</span>
        </p>
        <BFormSelect
          v-model="selected"
          v-bind="$attrs"
          :state="errors[0] ? false : valid ? true : null"
          :options="options"
        />
        <p class="text-secondary FormInput__input__error">{{ errors[0] }}</p>
      </label>
    </div>
  </ValidationProvider>
</template>

<style lang="scss" scoped>

</style>
