<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  components: { BSpinner },
  props: [
    'variant',
    'showspinner',
    'btnText',
    'width',
    'height',
    'externalClass',
  ],
}
</script>

<template>
  <button
    :style="{ width, height }" class="btn btn-primary btn__primary" :class="externalClass"
    @click="$emit('onClick')"
  >
    <div class="w-100 d-flex align-items-center justify-content-center">
      <span class="btn__primary__text">{{ btnText }}</span>

      <BSpinner v-if="showspinner" class="ml-2" small :variant="variant || 'secondary'" />
    </div>
  </button>
</template>

<style lang="scss" scoped>

</style>
