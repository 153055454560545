/**
 * @usage:
 *
 *   <RadioBox label="Foo" value="foo" v-model="MySelectedValue" />

 *   <RadioBox label="Bar" value="bar" v-model="MySelectedValue" />

 *   <RadioBox label="Baz" value="baz" v-model="MySelectedValue" />
 *
 * data(){
 *    return {
 *      MySelectedValue: "",
 *    }
 *  }
 */

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: '',
      required: true,
    },
    modelValue: {
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      default: undefined,
    },
    rules: {
      type: Object,
      default() {
        return {
          required: true,
        }
      },
    },
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value
    },
  },
}
</script>

<template>
  <ValidationProvider v-slot="{ errors, valid }" :rules="rules">
    <label class="wrapper">
      <input
        :state="errors[0] ? false : valid ? true : null"
        class="checkbox" type="radio"
        :checked="isChecked"
        :value="value"
        :name="name"
        @change="$emit('change', value)"
      >
      <span class="FormRadio__btn__text ml-2">{{ label }}</span>
    </label>
  </ValidationProvider>
</template>

<style lang="postcss" scoped>
.wrapper{
    display:flex
}
input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: #FFFFFF;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.12em solid #253a93;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #ec168d;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
</style>
