<script>
import axios from 'axios'

import { ValidationObserver } from 'vee-validate'
import { BForm } from 'bootstrap-vue'
import BaseInput from '../components/Forms/BaseInput.vue'
import BaseSelect from '../components/Forms/BaseSelect.vue'
import { age, contactObjects, customersType, profession } from '../data/index'
import BaseTextArea from '../components/Forms/BaseTextArea.vue'
import AppButton from '@/components/ui/Button.vue'
export default {
  components: {
    ValidationObserver,
    AppButton,
    BForm,
    BaseInput,
    BaseSelect,
    BaseTextArea,
  },
  age,
  contactObjects,
  customersType,
  profession,

  data() {
    return {
      submitted: false,
      showspinner: false,
      firstName: '',
      lastName: '',
      customerType: '',
      object: '',
      age: '',
      note: '',
      profession: '',
      city: '',
    }
  },

  methods: {
    async submitForm() {
      const url = '/contact/contact-us-ain-saiss'
      this.showspinner = true
      const formData = {
        age: this.age,
        city: this.city,
        comment: this.note,
        firstName: this.firstName,
        function: this.profession,
        lastName: this.lastName,
        purpose: this.object,
        requesterDescription: this.customerType,
      }
      try {
        const rawResponse = await axios.post(url, formData)
        this.submitted = true
        this.showspinner = false
        setTimeout(() => {
          location.reload()
        }, 750)
      }
      catch (error) {
        this.submitted = false
        this.showspinner = false
      }
    },
  },
}
</script>

<template>
  <div class="page1__headerContainer">
    <div class="page1 page__bg">
      <div class="page1__container px-5">
        <div class="page1__header text-center d-flex align-items-center">
          <div />
          <h1 class="text-uppercase flex-column page1__header__heading">
            Nous Contacter
          </h1>
        </div>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
          <BForm ref="myform" @submit.prevent="handleSubmit(submitForm)">
            <div class="row page1__inputRow">
              <div class="col-md-6 col-12">
                <BaseInput
                  v-model="lastName"
                  :is-required="true"
                  label="Nom"
                  :rules="{ required: true }"
                />
              </div>
              <div class="col-md-6 col-12">
                <BaseInput
                  v-model="firstName"
                  :is-required="true"
                  label="Prénom"
                  :rules="{ required: true }"
                />
              </div>
              <div class="col-md-6 col-12">
                <BaseSelect
                  v-model="customerType"
                  label="Vous êtes"
                  :is-required="true"
                  :options="$options.customersType"
                  :rules="{ required: true }"
                />
              </div>
              <div class="col-md-6 col-12">
                <BaseSelect
                  v-model="object"
                  label="Objet de la prise de contact"
                  :is-required="true"
                  :options="$options.contactObjects"
                  :rules="{ required: true }"
                />
              </div>
              <div class="col-md-6 col-12">
                <BaseSelect
                  v-model="age"
                  label="Age"
                  :is-required="true"
                  :options="$options.age"
                  :rules="{ required: true }"
                />
              </div>
              <div class="col-md-6 col-12">
                <BaseTextArea
                  v-model="note"
                  rows="5"
                  :is-required="true"
                  label="Autres Commentaires"
                  :rules="{ required: true }"
                />
              </div>
              <div class="col-md-6 col-12">
                <BaseSelect
                  v-model="profession"
                  label="Profession"
                  :is-required="true"
                  :options="$options.profession"
                  :rules="{ required: true }"
                />
              </div>
              <div class="col-md-6 col-12">
                <BaseInput
                  v-model="city"
                  :is-required="true"
                  label="Ville"
                  :rules="{ required: true }"
                />
              </div>
            </div>
            <div class="text-center mt-4">
              <AppButton
                width="280.15px"
                height="38.58px"
                btn-text="Valider mes informations"
                external-class="page1__btn"
                :showspinner="showspinner"
                :disabled="showspinner"
              />
            </div>
          </BForm>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
