<script>
export default {
  name: 'BaseCheckbox',
  props: {
    label: String,
    value: Array,
    inputValue: String,
  },
  emits: ['input'],
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<template>
  <label>
    <input v-model="model" type="checkbox" :value="inputValue">
    <span class="FormRadio__btn__text ml-2">{{ label }}</span>
  </label>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: #FFFFFF;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: #253a93;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #253a93 ;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid #253a93;
  outline-offset: max(2px, 0.15em);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabledj);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}
</style>
