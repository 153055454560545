<script>
import SuccessPage from '@/components/pages/Success.vue'
import Bars from '@/components/pages/MultiStepForm/Bars.vue'
export default {
  components: {
    SuccessPage,
    Bars,
  },
  data() {
    return {}
  },
}
</script>

<template>
  <div class="page2__headerContainer">
    <div class="multistep  d-flex justify-content-center">
      <div>
        <SuccessPage />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
