<script>
import { ValidationObserver } from 'vee-validate'
import { BForm } from 'bootstrap-vue'
import axios from 'axios'
import BaseInput from '../components/Forms/BaseInput.vue'
import BaseSelect from '../components/Forms/BaseSelect.vue'
import { age, contactObjects, customersType, metiers, profession } from '../data'
import BaseTextArea from '../components/Forms/BaseTextArea.vue'
import RadioBoxGroup from '../components/Forms/RadioBoxGroup.vue'
import CheckBoxGroup from '../components/Forms/CheckBoxGroup.vue'
import FileInput from '../components/Forms/FileInput.vue'
import AppButton from '@/components/ui/Button.vue'

export default {
  components: {
    ValidationObserver,
    AppButton,
    BForm,
    BaseInput,
    BaseSelect,
    BaseTextArea,
    RadioBoxGroup,
    CheckBoxGroup,
    FileInput,
  },
  age,
  contactObjects,
  customersType,
  profession,
  metiers,
  data() {
    return {
      submitted: false,
      showspinner: false,
      firstName: '',
      lastName: '',
      driverLicence: '',
      mobility: '',
      phone: '',
      email: '',
      age: '',
      note: '',
      profession: '',
      resume: null,
      metiers: [],
      permisOptions: [
        { label: 'Oui', value: 'oui' },
        { label: 'Non', value: 'non' },
      ],
      mobilityOptions: [
        { label: 'Oui', value: 'oui' },
        { label: 'Non', value: 'non' },
      ],
    }
  },

  methods: {
    async submitForm() {
      this.showspinner = true

      const url = '/contact/join-us-ain-saiss'

      const form = {
        lastName: this.lastName,
        firstName: this.firstName,
        phoneNumber: this.phone,
        email: this.email,
        permit: this.driverLicence === 'oui',
        mobility: this.mobility === 'oui',
        jobs: this.metiers,
        age: this.age,
        function: this.profession,
        comment: this.note,
      }
      try {
        const form_data = new FormData()

        form_data.append('file', this.resume)
        for (const [key, value] of Object.entries(form))
          form_data.append(`form.${key}`, value)

        const rawResponse = await axios.post(url, form_data, {
          headers: {
            'Accept': '*/*',
            'Accept-Encoding': 'gzip, deflate, br',
          },
        })

        this.showspinner = false
        setTimeout(() => {
          location.reload()
        }, 750)
      }
      catch (error) {
        this.showspinner = false
        console.log(error)
      }
    },
    captureFile(payload) {
      this.resume = payload
    },
  },
}
</script>

<template>
  <div class="page1__headerContainer">
    <div class="page1 page__bg">
      <div class="page1__container px-5">
        <div class="page1__header text-center d-flex align-items-center">
          <div />
          <h1 class="text-uppercase flex-column page1__header__heading">
            Nous Rejoindre
          </h1>
        </div>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
          <BForm ref="myform" @submit.prevent="handleSubmit(submitForm)">
            <div class="row page1__inputRow">
              <div class="col-md-6 col-12">
                <BaseInput
                  v-model="lastName"
                  :is-required="true"
                  label="Nom"
                  :rules="{ required: true }"
                />
              </div>
              <div class="col-md-6 col-12">
                <BaseInput
                  v-model="firstName"
                  :is-required="true"
                  label="Prénom"
                  :rules="{ required: true }"
                />
              </div>
              <div class="col-md-6 col-12">
                <RadioBoxGroup
                  v-model="driverLicence"
                  legend="Permis"
                  :is-required="true"
                  name="driverLicence"
                  :options="permisOptions"
                />
              </div>
              <div class="col-md-6 col-12">
                <RadioBoxGroup
                  v-model="mobility"
                  legend="Mobilite"
                  :is-required="true"
                  name="mobilty"
                  :options="mobilityOptions"
                />
              </div>
            </div>
            <div class="row page1__inputRow">
              <div class="col-md-6 col-12">
                <div>
                  <BaseSelect
                    v-model="age"
                    label="Age"
                    :is-required="true"
                    :options="$options.age"
                    :rules="{ required: true }"
                  />
                </div>
                <div class="mt-2">
                  <BaseInput
                    v-model="phone"
                    :is-required="true"
                    label="Téléphone"
                    :rules="{ required: true, numeric: true }"
                  />
                </div>
                <div class="mt-2">
                  <BaseInput
                    v-model="email"
                    :is-required="true"
                    label="E-mail"
                    :rules="{ required: true, email: true }"
                  />
                </div>
                <div class="">
                  <BaseSelect
                    v-model="profession"
                    label="Profession"
                    :is-required="true"
                    :options="$options.profession"
                    :rules="{ required: true }"
                  />
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div>
                  <CheckBoxGroup
                    v-model="metiers"
                    :is-required="true"
                    legend="Métiers"
                    :options="$options.metiers"
                  />
                </div>
                <div class="mt-2">
                  <BaseTextArea
                    v-model="note"
                    rows="5"
                    :is-required="true"
                    label="Autres Commentaires"
                    :rules="{ required: true }"
                  />
                </div>
                <div>
                  <FileInput
                    label="Joindre CV/Lettre de motivation"
                    accept="application/msword, application/vnd.ms-powerpoint, text/plain, application/pdf"
                    @fileUpdated="captureFile"
                  />
                </div>
              </div>
            </div>
            <div class="text-center mt-4">
              <AppButton
                width="280.15px"
                height="38.58px"
                btn-text="Valider mes informations"
                external-class="page1__btn"
                :showspinner="showspinner"
                :disabled="showspinner"
              />
            </div>
          </BForm>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
