import { render, staticRenderFns } from "./BaseTextArea.vue?vue&type=template&id=be7dcc30&scoped=true&"
import script from "./BaseTextArea.vue?vue&type=script&lang=js&"
export * from "./BaseTextArea.vue?vue&type=script&lang=js&"
import style0 from "./BaseTextArea.vue?vue&type=style&index=0&id=be7dcc30&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be7dcc30",
  null
  
)

/* custom blocks */
import block0 from "./BaseTextArea.vue?vue&type=custom&index=0&blockType=BaseTextArea&label=Name&%3Arules=%7Brequired%3Atrue%7D&%3AisRequired=true&v-model=name"
if (typeof block0 === 'function') block0(component)

export default component.exports