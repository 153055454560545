<script>
import BaseCheckbox from './BaseCheckbox.vue'
export default {
  components: { BaseCheckbox },
  props: {
    value: {
      type: Array,
      required: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    legend: {
      type: String,
      default: '',
    },
    rules: {
      type: Object,
      default() {
        return {}
      },
    },
    options: {
      type: Array,
      required: true,
    },
  },
  emits: ['input'],
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

}
</script>

<template>
  <ValidationProvider v-slot="{ errors }" :rules="rules">
    <fieldset class="FormRadio  d-sm-block">
      <legend class="FormRadio__heading">
        <span v-if="legend" class="heading1">{{ legend }}</span>
        <span v-if="isRequired" class="text-danger">*</span>
      </legend>
      <div class="wrapper">
        <BaseCheckbox
          v-for="option in options"
          :key="option"
          v-model="model"
          :input-value="option"
          :label="option"
        />
      </div>

      <p class="text-secondary FormInput__input__error">
        {{ errors[0] }}
      </p>
    </fieldset>
  </ValidationProvider>
</template>

<style lang="scss" scoped>
fieldset {

padding: 0px 10px;
    border: 1px solid #253a93;

}

legend{
    width: auto;
}
.wrapper{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
</style>
