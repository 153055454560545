<script>
export default {}
</script>

<template>
  <div class="sectionbackground">
    <div class="bg_blue position-relative">
      <div class="pb-60">
        <div class="container">
          <div class="text-center font-18 sectionbackground__heading font_blue mt-50 font_NexaBold">
            Les conditions générales de vente de<br class="d-sm-block d-none">
            Aïn Saïss
          </div>
          <div class="px_5 mt-50">
            <div class=" py-2">
              <div class="font_blue font_NexaRegular font-12">
                Le site www.ain-saiss.ma/boutique est un site marchand «
                e-commerce » (ci-après le « Site ») accessible par Internet
                édité par la Société Sotherma, sise à l'adresse:
                <b>Gare ONCF, Aïn Saïss, Fes Banlieue, RC Fès 14254/Fès</b>
                et <b>ICE: 001561875000028.</b>
                <br>
                Les présentes conditions générales de vente (ci-après les « CGV
                »), définissent les modalités et les conditions dans lesquelles
                Aïn Saïss livre au domicile des clients consommateurs ayant
                souscrit au service proposé sur son Site Internet
                (www.ain-saiss.ma/boutique), un ou plusieurs lots de Produits.
                Il est précisé que les Produits achetés sur ce Site ne sont pas
                destinés à être revendus par les Clients.
                <br>
                Toute commande d’un Produit proposé sur le Site implique
                l’adhésion sans réserve du Client aux présentes conditions
                générales de vente. Une fois qu’il a accepté les CGV, le client
                devient client du service www.ain-saiss.ma/boutique (« Client
                »).
                <br>
                Il est précisé que la présente offre est valable uniquement dans
                certaines zones géographiques de livraison (« Zone(s) de
                livraison »). Pour connaître ces zones, le Client est invité à
                consulter le site Internet www.ain-saiss.ma/boutique.
                <br>
                Le Client déclare être majeur ou titulaire d’une autorisation
                parentale et disposer de la capacité juridique à contracter lui
                permettant d’effectuer une commande sur le présent site
                internet.
              </div>

              <br>
            </div>
            <div class=" py-2">
              <div class="font_blue font_NexaRegular font-18">
                <span class="font_darkRed font-18">1 </span>. PRESENTATION DES
                PRODUITS
              </div>

              <br>
              <div class="font_blue font_NexaRegular font-12">
                Les caractéristiques des produits proposés à la vente sont
                présentées dans la page d'accueil de notre Site. Les
                photographies n’entrent pas dans le champ contractuel. La
                responsabilité de www.ain-saiss.ma/boutique ne peut être
                engagée si des erreurs s’y sont introduites.
              </div>

              <br>
              <div class="font_blue font_NexaRegular font-12">
                Tous les textes et images présentés sur le Site sont réservés au
                titre des droits d’auteur et de propriété intellectuelle. Leur
                reproduction, même partielle, est strictement interdite.
              </div>
            </div>
            <div class=" py-2">
              <div class="font_blue font_NexaRegular font-18">
                <span class="font_darkRed font-18">2 </span>. DISPONIBILITE DES
                PRODUITS
              </div>

              <br>
              <div class="font_blue font_NexaRegular font-12">
                Tous les produits présents dans le catalogue sont commercialisés
                jusqu’à épuisement des stocks. www.ain-saiss.ma/boutique se
                réserve le droit de retirer du catalogue un article, et ceci
                sans préavis.
              </div>

              <br>
              <div class="font_blue font_NexaRegular font-12">
                Dans l’hypothèse exceptionnelle où un produit se révélerait
                indisponible, après passation de la commande, en raison d’un
                problème de transport ou d’un écart de stock,
                www.ain-saiss.ma/boutique s’engage à en informer le Client par
                email ou par téléphone et à lui apporter toute précision
                complémentaire nécessaire au bon déroulement de sa commande ou à
                l’annulation de cette dernière si aucun autre produit ne
                convient au Client en remplacement du produit indisponible.
              </div>
            </div>
            <div class=" py-2">
              <div class="font_blue font_NexaRegular font-18">
                <span class="font_darkRed font-18">3 </span>. INSCRIPTION
              </div>

              <br>
              <div class="font_blue font_NexaRegular font-12">
                Lors de la première commande, et afin de personnaliser
                l’environnement de chaque Client, une adresse mail et un mot de
                passe sont choisis et saisis par celui-ci, sous réserve de
                l’exactitude de l’ensemble des informations qu’il aura fournies.
                Un contrôle d’unicité de l’identifiant est alors effectué par le
                système. L'adresse mail et le mot de passe valent preuve de
                l’identité du Client et l’engagent sur toute commande passée par
                leur intermédiaire.
              </div>

              <br>
              <div class="font_blue font_NexaRegular font-12">
                Le Client est le seul responsable de son adresse mail et de son
                mot de passe. Il supportera seul les conséquences qui pourraient
                résulter de toute utilisation par des tiers qui auraient eu
                connaissance de ceux-ci, à moins qu’il ne démontre que la
                connaissance de son identifiant et de son mot de passe résulte
                d’une faute de www.ain-saiss.ma/boutique.
              </div>

              <br>
              <div class="font_blue font_NexaRegular font-12">
                En cas d’oubli de son mot de passe ou de son adresse mail ou en
                cas de crainte qu’un tiers ait pu en avoir connaissance, le
                Client dispose sur le Site d’une fonction lui permettant de
                retrouver son identifiant et de choisir un nouveau mot de passe.
                Le système supprime alors automatiquement les coordonnées
                bancaires éventuellement enregistrées lors d’une précédente
                commande.
              </div>
            </div>
            <div class=" py-2">
              <div class="font_blue font_NexaRegular font-18">
                <span class="font_darkRed font-18">4 </span>. COMMANDE
              </div>

              <br>
              <div class="font_blue font_NexaRegular font-12">
                Après avoir choisi le ou les produits souhaités, ce ou ces
                derniers sont rajoutés automatiquement à la liste de commandes
                effectuées. Le Client peut à tout instant modifier sa commande.
              </div>
              <br>
              <div class="font_blue font_NexaRegular font-12">
                En passant à l’étape Commander, le Client est tenu de renseigner
                les informations d’identification lui permettant d’être livré.
                Le Client est invité à procéder à son identification pour chaque
                commande sur le Site.
              </div>

              <br>
              <div class="font_blue font_NexaRegular font-12">
                Toute saisie de commande fait simultanément l’objet d’un
                récapitulatif avant que le Client ne soit invité à confirmer sa
                commande en indiquant l’adresse de livraison et le moyen de
                paiement. Le Client a alors la possibilité de rectifier ou
                compléter sa commande. Le Client est ensuite invité à « valider
                et payer » ; cette confirmation de commande constitue validation
                ferme et définitive sous la réserve visée à l’alinéa suivant.
              </div>

              <br>
              <div class="font_blue font_NexaRegular font-12">
                www.ain-saiss.ma/boutique se réserve la possibilité de ne pas
                accepter une commande, pour un motif légitime, tenant par
                exemple à une commande d’un Client avec lequel existerait, au
                jour de la commande, un litige en cours relatif au paiement
                d’une commande antérieure ou encore toute commande mentionnant
                une adresse de livraison qui s’avérerait incohérente ou erronée;
              </div>

              <br>
              <div class="font_blue font_NexaRegular font-12">
                Dans l’hypothèse où une commande ne peut être acceptée pour l’un
                des motifs susvisés, www.ain-saiss.ma/boutique informe le
                Client par courrier électronique. Il est notamment demandé au
                Client de consulter régulièrement sa boîte email pour prendre
                connaissance de tout message qui pourrait lui être adressé par
                www.ain-saiss.ma/boutique concernant l’invalidité de sa
                commande ou l’impossibilité de procéder à la livraison.
              </div>

              <br>
              <div class="font_blue font_NexaRegular font-12">
                www.ain-saiss.ma/boutique ne pourra être tenue pour
                responsable de la non-réception d’une commande en cas d’erreur,
                volontaire ou non, dans les données d’identification fournies
                par le Client lors du remplissage de ses informations
                d’identification.
              </div>

              <br>
              <div class="font_blue font_NexaRegular font-12">
                Le Client reconnaît que le contrat de vente est parfait au
                moment de la confirmation de la commande lors du double-clic. La
                commande est ensuite confirmée au Client par l’envoi d’un email
                de confirmation. Le transfert des risques au Client intervient
                au moment de la livraison à domicile. Les informations énoncées
                par le Client, lors de la prise de commande, engagent celui-ci :
                en cas d’erreur du Client dans le libellé de ses coordonnées ou
                de celles du lieu de livraison, notamment son nom, prénom,
                adresse, numéro de téléphone, adresse électronique ou numéro de
                carte bancaire ou de paiement, ayant pour conséquence la perte
                des produits, le Client reste responsable du paiement des
                produits perdus.
              </div>
            </div>
            <div class=" py-2">
              <div class="font_blue font_NexaRegular font-18">
                <span class="font_darkRed font-18">5 </span>. PRIX
              </div>

              <br>
              <div class="font_blue font_NexaRegular font-12">
                Les prix mentionnés par défaut sur le site sont indiqués TTC
                (toutes taxes comprises) et en dirhams marocains.
              </div>
              <br>
              <div class="font_blue font_NexaRegular font-12">
                La TVA est appliquée au taux en vigueur au moment de la
                passation de la commande.
              </div>
              <br>
              <div class="font_blue font_NexaRegular font-12">
                Les frais de transport sont automatiquement offerts à partir
                d'un panier de 150 dhs d'achats sur www.ain-saiss.ma/boutique.
              </div>
              <br>
              <div class="font_blue font_NexaRegular font-12">
                www.ain-saiss.ma/boutique se réserve le droit de modifier ses
                prix à tout moment. Les produits commandés sont facturés au prix
                en vigueur lors de l’enregistrement de la commande.
              </div>
            </div>
            <div class=" py-2">
              <div class="font_blue font_NexaRegular font-18">
                <span class="font_darkRed font-18">6 </span>. MODE ET MOYEN DE
                PAIEMENT :
              </div>

              <br>
              <div class="font_blue font_NexaRegular font-12">
                Pour régler la commande, le Client choisit le moyen de paiement
                parmi ceux proposés par www.ain-saiss.ma/boutique au niveau de
                la page de paiement : paiement en espèces à la livraison,
                paiement par carte bancaire à la livraison, ou par carte
                bancaire sur le Site. Les autres moyens de paiement (chèque) ne
                sont pas acceptés. Le montant à payer est affiché dans le
                récapitulatif de la commande, envoyé également par mail au
                Client.
              </div>

              <br>
              <div class="font_blue font_NexaRegular font-12">
                <b>Dans le cas d’un paiement par carte bancaire, la remise de la
                  transaction pour débit du compte Client est effectuée dans la
                  journée qui suit la date de la confirmation de livraison.</b>
              </div>
              <br>
              <div class="font_blue font_NexaRegular font-12">
                Les paiements en ligne effectués par le Client sont sécurisés
                par le Centre Monétique Interbancaire (CMI) qui offre un service
                de paiement entièrement sécurisé. En cas de paiement par carte
                bancaire, les dispositions relatives à l’utilisation frauduleuse
                du moyen de paiement prévues dans les conventions conclues entre
                le Client et l’émetteur de la carte et entre
                www.ain-saiss.ma/boutique et son établissement bancaire
                s’appliquent.
              </div>

              <br>
              <div class="font_blue font_NexaRegular font-12">
                <b>Toute commande avec choix d’un paiement par carte bancaire
                  n’est considérée comme effective que lorsque les centres de
                  paiement concernés ont donné leur accord. En cas de refus des
                  dits centres, la commande est automatiquement annulée et le
                  Client prévenu par un message à l’écran.</b>
              </div>
            </div>
            <div class=" py-2">
              <div class="font_blue font_NexaRegular font-18">
                <span class="font_darkRed font-18">7 </span>. PREUVE DES
                TRANSACTIONS PAYES PAR CARTE BANCAIRE
              </div>
              <br>
              <br>
              <div class="font_blue font_NexaRegular font-12">
                Les données enregistrées par le CMI sur la plate-forme de
                paiement en ligne pour le compte de www.ain-saiss.ma/boutique
                constituent la preuve des transactions commerciales passées
                entre le Client et www.ain-saiss.ma/boutique.
              </div>
            </div>
            <div class=" py-2">
              <div class="font_blue font_NexaRegular font-18">
                <span class="font_darkRed font-18">8 </span>. LIVRAISON
              </div>
              <br>
              <br>
              <div class="font_blue font_NexaRegular font-12">
                Les données enregistrées par le CMI sur la plate-forme de
                paiement en ligne pour le compte de www.ain-saiss.ma/boutique
                constituent la preuve des transactions commerciales passées
                entre le Client et www.ain-saiss.ma/boutique.
              </div>
              <div class=" py-2">
                <br>
                <div class="font_blue font_NexaRegular font-14">
                  1. Lieu de livraison
                </div>
                <br>
                <br>
                <div class="font_blue font_NexaRegular font-12">
                  www.ain-saiss.ma/boutique livre pour l'instant à Casablanca
                  et régions, Rabat et régions. La liste exhaustive des villes
                  desservies est diSponible sur www.ain-saiss.ma/boutique.
                  <br>
                  Les autres villes de livraison seront mentionnées sur le site
                  au fur et à mesure de leur future couverture.
                  <br>
                  Les produits sont livrables à l’adresse de livraison que le
                  Client aura renseignée sur sa commande.
                  <br>
                  <b>L’adresse de livraison désignée par le Client peut être
                    différente de l’adresse de facturation.
                  </b>
                </div>

                <br>
                <div class="font_blue font_NexaRegular font-14">
                  2. Frais de Livraison
                </div>
                <!-- <br />
                <br /> -->
                <div class="font_blue font_NexaRegular font-12">
                  Les frais de livraison sur les villes de livraison indiquées
                  ci-dessus sont gratuits.
                </div>
                <!-- <br /> -->
                <br>
                <div class="font_blue font_NexaRegular font-14">
                  3. Délais de livraison:
                </div>
                <!-- <br /> -->
                <br>
                <div class="font_blue font_NexaRegular font-12">
                  Les délais de livraison annoncés sur le site sont décomptés à
                  partir de la date de sortie des produits commandés par le
                  Client des dépôts de www.ain-saiss.ma/boutique. Ces délais
                  sont de 48h à compter de la date de validation de la commande
                  sur le Site (hors dimanche et jours fériés).
                  <br>

                  Fréquence de livraison :
                  <br>

                  www.ain-saiss.ma/boutique offre aux Clients la possibilité
                  de choisir le lieu et la fréquence de livraison. Lors de
                  l’inscription, le Client a le choix entre deux formules :
                  <br>

                  Formule ponctuelle :
                  <br>

                  Livraison unique, à la date choisie (hors dimanche et jours
                  fériés).
                  <br>

                  Formule Abonnement :
                  <br>

                  La livraison peut se faire, au choix du Client, à fréquence
                  définie, moyennant la création d’un compte abonné. Le Client a
                  la possibilité d’apporter des modifications à sa commande 72h
                  avant la livraison. Si la commande est déjà programmée, et un
                  e-mail ou SMS de confirmation déjà reçus par le Client
                  concernant le jour exact de livraison de ladite commande, la
                  modification sera enregistrée pour la livraison d’après. Le
                  Client peut, se désabonner à tout moment et gratuitement en se
                  connectant sur son compte et en cliquant sur « Supprimer
                  définitivement mon compte ». Le système supprime alors
                  automatiquement les coordonnées bancaires enregistrées lors de
                  la création du compte abonné.
                </div>
                <!-- <br /> -->
                <br>
                <div class="font_blue font_NexaRegular font-14">
                  4. Absence Client
                </div>
                <!-- <br /> -->
                <br>
                <div class="font_blue font_NexaRegular font-12">
                  Si personne n’est présent pour réceptionner la commande au
                  domicile indiqué par le Client, les produits seront retournés
                  à www.ain-saiss.ma/boutique qui pourra les réexpédier à la
                  demande du Client.

                  <br>
                  A la livraison de la commande, le Client (ou la personne
                  désignée par ses soins en cas d’absence du Client) doit
                  obligatoirement vérifier la conformité de la commande livrée
                  en la présence du transporteur.
                </div>
              </div>
            </div>
            <div class=" py-2">
              <div class="font_blue font_NexaRegular font-18">
                <span class="font_darkRed font-18">9 </span>. SERVICE CLIENT
              </div>
              <!-- <br /> -->
              <br>
              <div class="font_blue font_NexaRegular font-12">
                Pour toute question relative à un achat effectué sur le Site, ou
                en cas d’anomalie concernant la livraison, le service clients de
                www.ain-saiss.ma/boutique peut être joint :
                <ul>
                  <li>
                    Par téléphone : 06 08 051 051 (coût d’une communication
                    locale). 24H/24 et 7J/7
                  </li>
                  <li>
                    Si l’internaute dispose déjà d’un compte abonné sur le Site,
                    il peut se rendre sur la rubrique « Mon compte » pour
                    effectuer une réclamation.
                  </li>
                </ul>
                Toute réclamation d’erreur de livraison doit être formulée par
                le Client à www.ain-saiss.ma/boutique le jour même de la
                livraison ou au plus tard le premier jour ouvré suivant la
                livraison.
              </div>
            </div>
            <div class=" py-2">
              <div class="font_blue font_NexaRegular font-18">
                <span class="font_darkRed font-18">10 </span>. DONNEES
                PERSONNELLES
              </div>
              <!-- <br /> -->
              <br>
              <div class="font_blue font_NexaRegular font-12">
                Le renseignement des données personnelles collectées aux fins de
                vente à distance est obligatoire pour la confirmation, et la
                validation de la commande du Client ainsi que son suivi et son
                expédition ; en leur absence, la commande du Client ne pourra
                être traitée: ces données obligatoires sont identifiées par un
                astérisque sur le formulaire qui est rempli lors de la commande,
                les autres sont facultatives.

                <br>
                Les informations demandées sont traitées de manière strictement
                confidentielle. www.ain-saiss.ma/boutique attache un soin tout
                particulier à la protection des données personnelles de ses
                Clients et met tout en œuvre pour respecter ce droit.

                <br>
                Conformément à la loi 09-08 relative à la protection des
                personnes physiques à l’égard du traitement des données à
                caractère personnel, toute personne concernée dispose d’un droit
                d’accès, de rectification, et d’opposition relatif aux données
                qu’il a renseignées. Pour l’exercer, il suffit d’en faire la
                demande par courrier en indiquant le nom, prénom, adresse et si
                possible la référence Client adressée à Gare ONCF, Aïn Saïss,
                Fes Banlieue .

                <br>
                Les Clients dont les données personnelles ont été collectées
                peuvent s’adresser à www.ain-saiss.ma/boutique pour exercer
                leurs droits d’accès, de rectification et d’opposition
                conformément aux dispositions de la loi 09-08 susmentionnée.
              </div>
            </div>
            <div class=" py-2">
              <div class="font_blue font_NexaRegular font-18">
                <span class="font_darkRed font-18">11 </span>. PROPRIETE
                INTELLECTUELLE
              </div>
              <!-- <br /> -->
              <br>
              <div class="font_blue font_NexaRegular font-12">
                Tous les éléments du Site (notamment contenus, code,
                architecture…) sont protégés au titre du droit d’auteur, des
                marques ou des brevets. Leur utilisation sans l’autorisation
                préalable et écrite de www.ain-saiss.ma/boutique ou des autres
                titulaires de droits est susceptible de constituer une
                contrefaçon et de donner lieu à des poursuites.
              </div>
            </div>
            <div class=" py-2">
              <div class="font_blue font_NexaRegular font-18">
                <span class="font_darkRed font-18">12 </span>. RESPONSABILITÉ
              </div>
              <!-- <br /> -->
              <br>
              <div class="font_blue font_NexaRegular font-12">
                www.ain-saiss.ma/boutique atteste que l’ensemble des produits
                commercialisés sur le Site est conforme à la législation
                Marocaine en vigueur. La responsabilité de
                www.ain-saiss.ma/boutique ne pourra être recherchée en cas de
                mauvaise utilisation des produits. La responsabilité de
                www.ain-saiss.ma/boutique ne saurait être engagée pour tous
                les inconvénients et dommages inhérents à l’utilisation
                d’Internet notamment une rupture du service, une intrusion
                extérieure ou la présence de virus informatique ou tout autre
                cas de force majeure ou cas fortuit. www.ain-saiss.ma/boutique
                rappelle que seuls les enfants mineurs autorisés à détenir un
                moyen de paiement en vertu d’un accord parental ont la
                possibilité de commander des produits sur le Site et peuvent
                effectuer des achats relevant des actes de la vie courante.
              </div>
            </div>
            <div class=" py-2">
              <div class="font_blue font_NexaRegular font-18">
                <span class="font_darkRed font-18">13 </span>. FORCE MAJEURE
              </div>
              <!-- <br /> -->
              <br>
              <div class="font_blue font_NexaRegular font-12">
                www.ain-saiss.ma/boutique n’est tenu pour l’exécution de ses
                obligations que dans la mesure où aucun événement de force
                majeure ne vient les entraver.
              </div>
            </div>
            <div class=" py-2">
              <div class="font_blue font_NexaRegular font-18">
                <span class="font_darkRed font-18">14 </span>. MODIFICATION
              </div>
              <!-- <br /> -->
              <br>
              <div class="font_blue font_NexaRegular font-12">
                www.ain-saiss.ma/boutique se réserve la possibilité d’adapter
                ou de modifier à tout moment les présentes conditions générales
                de vente. Les Clients sont priés de les consulter régulièrement.
                Les conditions générales de vente applicables sont celles en
                vigueur le jour de votre commande.
              </div>
            </div>
            <div class=" py-2">
              <div class="font_blue font_NexaRegular font-18">
                <span class="font_darkRed font-18">15 </span>. INTEGRALITE
              </div>
              <!-- <br /> -->
              <br>
              <div class="font_blue font_NexaRegular font-12">
                Les présentes conditions générales de vente sont constituées de
                l’intégralité des clauses qui les composent. Le fait de ne pas
                se prévaloir à un moment donné de l’une quelconque des clauses
                des présentes, ne peut valoir renonciation à se prévaloir
                ultérieurement de ces mêmes clauses. En cas d’invalidité de
                l’une d’entre elles, les autres clauses demeurent valables.
              </div>
            </div>
            <div class=" py-2">
              <div class="font_blue font_NexaRegular font-18">
                <span class="font_darkRed font-18">15 </span>. DROIT APPLICABLE
              </div>
              <!-- <br /> -->
              <br>
              <div class="font_blue font_NexaRegular font-12">
                Les présentes conditions générales de vente sont soumises à la
                loi Marocaine.

                <br>
                Dans le cas où un différend surgirait entre les Parties à
                l’occasion de l’application, de l’interprétation ou de
                l’exécution des présentes conditions générales de vente, les
                Parties chercheront d’abord une solution amiable à ce litige.

                <br>
                A défaut de solution amiable, toutes contestations, différents
                ou litiges qui pourraient surgir entre les Parties à l’occasion
                de l’interprétation, l’application ou l’exécution du présent
                contrat seront portés devant les tribunaux de Fès.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sectionbackground {
    background-color: #e3f4fd !important;
    padding-top: 230px;
    padding-bottom: 100px;
}

.font-12 {
    font-size: 12px;
}

.font-18 {
    font-size: 18px;
}

.font_blue {
    color: #203a84 !important;
}

.font_darkRed {
    color: red;
}

.font_NexaBold {
    font-family: "Nexa Bold";
}

.font_NexaRegular {
    font-family: "NexaRegular";
}

.px_5 {
    padding-left: 5vw;
    padding-right: 5vw;
}

.mt-50 {
    margin-top: 50px;
}

@media (max-width: 1199px) {
    .sectionbackground {
        padding-top: 150px;
    }

    .px_5 {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 575px) {
    .sectionbackground {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 90px;
    }
}
</style>
