<script>
import PlayIcon from '@/assets/svgs/Play.vue'
export default {
  components: { PlayIcon },
  data() {
    return {
      text: '',
      retourText: 'retour',
      orderno: null,
      form_icon: require('../../assets/images/common/google_forms.svg'),
    }
  },
  async created() {
    this.orderno = await this.$store.dispatch(
      'getOrderId',
      this.$route.query.order,
    )

    // this.orderno = this.$route.query.order;
    this.$store.commit('clearCart')
  },
}
</script>

<template>
  <div class="multistep__step4 d-flex justify-content-center">
    <div>
      <div class="multistep__step4__box">
        <p class="multistep__step4__box__text">
          Commande Numéro <span class="alternate">{{ orderno }}</span> finalisée
        </p>
      </div>
      <div class="form font_NexaRegular pt-5 pb-0">
        <div class="">
          Afin de vous fournir une meilleure expérience,
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSea_b9-wLz_LbMdWbUXV84619eT6Md_GDWhk-bERGgSlhr6pA/viewform?vc=0&c=0&w=1&flr=0&fbzx=506367224726974660"
            target="_blank" class="cursor-pointer"
          >partagez avec nous votre avis<img
            class="ml-2 mr-1"
            :src="form_icon" width="20px"
          >
          </a>
          sur notre service de livraison en ligne.
          <br>
          Vos réponses nous permettront de mieux répondre à vos attentes.
        </div>
      </div>
      <button
        class="
          btn
          mx-auto
          multistep__step4__retour
          d-flex
          justify-content-center
          align-items-center
          pt-5
        " @click="$router.push('/')"
      >
        <PlayIcon />
        <p class="multistep__step4__retour__text text-uppercase">
          {{ retourText }}
        </p>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
