<script>
export default {

  props: {
    label: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '*',
    },
    rules: {
      type: Object,
      default() {
        return {
          required: true,
        }
      },
    },
    isRequired: {
      type: Boolean,
      default: false,
    },

  },
  emits: ['fileUpdated'],
  methods: {
    async onFileChange(event) {
      const { valid } = await this.$refs.provider.validate(event)

      if (valid) {
        const file = event.target.files[0]
        this.$emit('fileUpdated', file)
      }
    },
  },
}
</script>

<template>
  <ValidationProvider ref="provider" v-slot="{ errors, validate }" :rules="rules">
    <label class="text-left">
      <p class="FormInput__heading">
        <span>{{ label }}</span>
        <span v-if="isRequired" class="text-danger">*</span>
      </p>
      <input
        class="file-input"
        type="file"
        :accept="accept"
        :state="errors[0] ? false : validate ? true : null"
        @change="onFileChange"
      >
      <p class="text-secondary FormInput__input__error mt-1">{{ errors[0] }}</p>

    </label>
  </ValidationProvider>
</template>

<style lang="scss" scoped>
label{
    width: 100%;
}
input[type=file] {
  width: 100%;
  color: #253a93;
  padding: 5px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #253a93;
}
input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background:   #253a93;
  padding: 10px 30px;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
  background: #253a93;
}
</style>
