import { render, staticRenderFns } from "./RadioBoxInput.vue?vue&type=template&id=19cd5d32&scoped=true&"
import script from "./RadioBoxInput.vue?vue&type=script&lang=js&"
export * from "./RadioBoxInput.vue?vue&type=script&lang=js&"
import style0 from "./RadioBoxInput.vue?vue&type=style&index=0&id=19cd5d32&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19cd5d32",
  null
  
)

/* custom blocks */
import block0 from "./RadioBoxInput.vue?vue&type=custom&index=0&blockType=RadioBox&label=Foo&value=foo&v-model=MySelectedValue"
if (typeof block0 === 'function') block0(component)
import block1 from "./RadioBoxInput.vue?vue&type=custom&index=1&blockType=RadioBox&label=Bar&value=bar&v-model=MySelectedValue"
if (typeof block1 === 'function') block1(component)
import block2 from "./RadioBoxInput.vue?vue&type=custom&index=2&blockType=RadioBox&label=Baz&value=baz&v-model=MySelectedValue"
if (typeof block2 === 'function') block2(component)

export default component.exports