import { render, staticRenderFns } from "./BaseSelect.vue?vue&type=template&id=cf91a324&scoped=true&"
import script from "./BaseSelect.vue?vue&type=script&lang=js&"
export * from "./BaseSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf91a324",
  null
  
)

/* custom blocks */
import block0 from "./BaseSelect.vue?vue&type=custom&index=0&blockType=BaseSelect&%3Aoptions=%5B'Foo'%2C'Bar'%5D&label=Name&%3Arules=%7Brequired%3Atrue%7D&%3AisRequired=true&v-model=name"
if (typeof block0 === 'function') block0(component)

export default component.exports