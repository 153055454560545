/**
 * @usage:
 *
 *   <BaseInput label="Name" :rules="{required:true}" :isRequired="true"  v-model="name" />

 *
 * data(){
 *    return {
 *      name: "",
 *    }
 *  }
 */

<script>
export default {
  inheritAttrs: false,
  // props: ['inputData', 'inputContainerExternalClass',],
  props: {
    value: {
      type: String,
      required: true,
    },
    containerClass: {
      type: String,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    rules: {
      type: Object,
    },

  },
  emits: ['input'],
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      }
    },
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value)
    },
  },

}
</script>

<template>
  <ValidationProvider v-slot="{ errors, valid }" :rules="rules">
    <div class="FormInput " :class="containerClass">
      <label :for="label" class="FormInput__inputContainer">
        <p class="FormInput__heading">
          <span>{{ label }}</span>
          <span v-if="isRequired" class="text-danger">*</span>
        </p>
        <input
          :id="label"
          class="FormInput__input" type="text"
          :value="value"
          v-bind="$attrs"
          :state="errors[0] ? false : valid ? true : null"
          @input="updateValue"
          v-on="listeners"
        >
        <p class="text-secondary mt-1 FormInput__input__error">{{ errors[0] }}</p>
      </label>
    </div>
  </ValidationProvider>
</template>
