/**
 * @usage:
 *
 *   <RadioBoxGroup legend="Foo" name="option" v-model="MySelectedValue" :options="options" />

 *
 * data(){
 *    return {
 *      MySelectedValue: "",
        options:[{label:'option 1', value:1},{label:'option 2',value:2}]
 *    }
 *  }
 */

<script>
import RadioBoxInput from './RadioBoxInput.vue'

export default {
  components: { RadioBoxInput },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    legend: {
      type: String,
      default: '',
    },
    rules: {
      type: Object,
      default() {
        return {
          required: true,
        }
      },
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      l: '',
    }
  },
}
</script>

<template>
  <ValidationProvider v-slot="{ errors }" :rules="rules">
    <fieldset class="FormRadio  d-sm-block">
      <legend class="FormRadio__heading">
        <span v-if="legend" class="heading1">{{ legend }}</span>
        <span v-if="isRequired" class="text-danger">*</span>
      </legend>
      <div class="wrapper">
        <RadioBoxInput
          v-for="(option) in options" :key="option.value"
          :value="option.value"
          :label="option.label"
          :name="name"
          :model-value="value"
          @change="$emit('input', option.value)"
        />
      </div>

      <p class="text-secondary FormInput__input__error">
        {{ errors[0] }}
      </p>
    </fieldset>
  </ValidationProvider>
</template>

<style lang="scss" scoped>
.wrapper{
    display:flex;
    gap:15px;
    justify-content:flex-start;
}
</style>
