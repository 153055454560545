export const customersType = [
  'Un consommateur de Ain Saïss',
  'Un client épicerie traditionnelle de Ain Saïss',
  'Un fournisseur de Ain Saïss, à préciser',
]

export const contactObjects = [
  'Je souhaite partager mon expérience Ain Saïss',
  'Je souhaite partager mon avis sur la marque Ain Saïss',
  'J\'ai une réclamation au sujet de la marque Ain Saïss',
  'Je souhaite rentrer en contact avec les équipes Ain Saïss',
  'Je souhaite me faire livrer Ain Saïss chez moi',
  'Je souhaite référencer Ain Saïss dans mon point de vente',
  ' Autre, à préciser',
]

export const age = [
  '< 15 ans',
  '15 à 25 ans',
  '25 à 35 ans',
  '35 à 45 ans',
  '45 à 55 ans',
  '55 à 65 ans',
  '65 à 75 ans',
  '> 75 ans ',
]

export const profession = [
  'Fonction publique',
  'Fonction privée',
  'Profession libérale',
  'Femme au foyer',
  'Sans',
]

export const metiers = [
  'INDUSTRIE',
  'COMMERCIAL',
  'PRODUCTION',
  'APRES VENTE',
  'MAINTENANCE',
  'MARKETING',
  'QUALITE',
  'FINANCE',
  'SUPPLY CHAIN',
  'RH',
  'TRANSPORT',
  'Autre À Préciser',
]
